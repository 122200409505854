<template>
    <div class="container gm-padding-y">
        <div class="flex flex-col gap-6 lg:grid lg:grid-cols-3 lg:gap-8 lg:items-end">
            <div>
                <h3 class="">
                    Choose your Voice.
                </h3>
            </div>
            <div>
                <p class="text-sm">
                    With Greetmate, you can choose from a variety of voices to represent your brand.
                </p>
            </div>
            <div>
                <p class="text-sm">
                    Greetmate's Voices sound natural and are easy to understand.
                </p>
            </div>
        </div>

        <div class="lg:-mx-4">
            <div
                class="flex gap-4 lg:gap-0 flex-col sm:grid grid-cols-2 lg:flex lg:flex-row mx-auto my-12 justify-center duration-300 ">
                <VoiceCard
                    v-for="(voice,key) in voices"
                    :class="{
                    'active': key === 0,
                }"
                    :number="key"
                    :voice="voice"/>
            </div>
        </div>
    </div>
</template>
<script setup>
import michael from '@/../images/voices/michael.png?w=1200&h=1200&format=webp';
import calvin from '@/../images/voices/calvin.jpg?w=1200&h=1200&format=webp';
import ana from '@/../images/voices/ana.png?w=1200&h=1200&format=webp';
import rebecca from '@/../images/voices/rebecca.jpg?w=1200&h=1200&format=webp';
import nikki from '@/../images/voices/nikki.png?w=1200&h=1200&format=webp';
import chris from '@/../images/voices/chris.png?w=1200&h=1200&format=webp';

import VoiceCard from "@/Pages/Index/Partials/VoiceCard.vue";

const voices = [
    {
        name: 'Michael',
        description: 'Michael is a friendly voice that is easy to understand.',
        src: michael,
        file: 'michael.mp3'
    },
    {
        name: 'Calvin',
        description: 'Calvin is a casual, American Voice',
        src: calvin,
        file: 'calvin.mp3'
    },
    {
        name: 'Ana',
        description: 'Young, British Female voice',
        src: ana,
        file: 'ana.mp3'
    },
    {
        name: 'Rebecca',
        description: 'Young, American Female voice',
        src: rebecca,
        file: 'rebecca.mp3'
    },
    {
        name: 'Nikki',
        description: 'Friendly, Intelligent American Female voice',
        src: nikki,
        file: 'nikki.mp3'
    },
    {
        name: 'Chris',
        description: 'Easy to understand, American Male Voice',
        src: chris,
        file: 'chris.mp3'
    },
]

</script>
